import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { Bg2Glassmorphism } from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./RepairPage/SectionHero";
import carRepair from "images/carrepair-hk.jpg";
import carRepair2 from "images/carrepair-hk2.jpg";
import SectionPart from "./RepairPage/SectionPart";
// import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageTool: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>汽車維修服務 - 長勝租車 GoodwayPass Car Rental </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <Bg2Glassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          repair={true}
          rightImg={""}
          heading="汽車零件銷售"
          btnText="各種車款新、舊零件訂購"
          subHeading={
            <div>愛車壞咗下下都訂新零件太貴？長勝拆車零件幫到你！</div>
          }
        />

        <SectionPart />
        <div className="w-full flex gap-2 justify-center">
          <div className={"flex-1 lg:w-[500px] max-w-full mx-auto text-center"}>
            <img className={""} src={carRepair} alt="" />
          </div>
          <div className={"flex-1 lg:w-[500px] max-w-full mx-auto text-center"}>
            <img className={""} src={carRepair2} alt="" />
          </div>
        </div>

        {/* <SectionStatistic /> */}

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageTool;
