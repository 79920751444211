import React, { FC } from 'react';
import carDocument from '../images/運輸處文件代辦.jpg'
import carDocument2 from '../images/代購汽車零件.jpg'
import carDocument3 from '../images/代辦運輸處文件.jpg'
import { useTranslation } from 'react-i18next';
import { Bg2Glassmorphism } from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHero from "./RepairPage/SectionHero";
import { Helmet } from 'react-helmet-async';
export interface PageAboutProps {
    className?: string;
  }
const CarDocumentPage: FC<PageAboutProps> = ({ className = "" }) => {
  const { t } = useTranslation('common');
  return (

    <div
    className={`nc-PageAbout overflow-hidden relative ${className}`}
    data-nc-id="PageAbout"
  >
    <Helmet>
      <title>運輸署文件代辦 - 長勝租車 GoodwayPass Car Rental </title>
    </Helmet>

    {/* ======== BG GLASS ======== */}
    <Bg2Glassmorphism />

    <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <SectionHero
        repair={true}
        rightImg={""}
        heading={t("CARDOCUMENT", { defaultValue: "運輸署文件代辦" })}
        btnText="WhatsApp 聯絡代辦服務"
        subHeading={
          <div>特快代辦政府驗車｜驗車｜私家車驗車！</div>
        }
        btnLink="https:///wa.me/85265107388"
      />

<div className="flex flex-col items-center space-y-10">
        {/* Poster Container */}
        <div className="w-full max-w-4xl">
          <div className="rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-[1.02]">
            <img
              src={carDocument} // Update with your actual image path
              alt="運輸處文件代辦"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Name Cards Container */}
        <div className="flex flex-wrap justify-center gap-6">
        
          <div className="w-[450px] sm:w-[450px] transition-transform duration-300 hover:-translate-y-2">
            <img
              src={carDocument3} // Update with your actual image path
              alt="代辦運輸處文件"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="w-[450px] sm:w-[450px] transition-transform duration-300 hover:-translate-y-2">
            <img
              src={carDocument2} // Update with your actual image path
              alt="代購汽車零件"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        </div>

      {/* <SectionStatistic /> */}

      {/* <SectionSubscribe2 /> */}
    </div>
  </div>


  );
};

export default CarDocumentPage;