import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { PostDataType } from "utils/blogAdapter";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
import WidgetCategories from "./WidgetCategories";
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";
import { fetchBlogPosts } from "utils/fetchBlogPosts";
import { adaptBlogPosts } from "utils/blogAdapter";
import { useTranslation } from "react-i18next";
import { TaxonomyType } from "data/types";

export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  totalPages?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  className?: string;
  postCardName?: "card3";
  heading?: string;
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts = [],
  totalPages = 1,
  currentPage = 1,
  onPageChange,
  postCardName = "card3",
  className = "",
  heading,
}) => {
  const { t, i18n } = useTranslation();
  const [recentPosts, setRecentPosts] = useState<PostDataType[]>([]);
  const [allTags, setAllTags] = useState<TaxonomyType[]>([]);

  useEffect(() => {
    // Fetch popular posts for widget
    const loadRecentPosts = async () => {
      try {
        const response = await fetchBlogPosts(1, 5, 'published');
        setRecentPosts(adaptBlogPosts(response.data));
      } catch (error) {
        console.error("Error loading recent posts:", error);
      }
    };

    if (posts.length > 0) {
      loadRecentPosts();
      
      // Extract all unique tags from posts
      const tagsMap = new Map<string, {count: number, href: string, id: string}>();
      
      posts.forEach(post => {
        post.categories.forEach(category => {
          if (category.taxonomy === 'tag') {
            const existing = tagsMap.get(category.name);
            if (existing) {
              tagsMap.set(category.name, {
                ...existing, 
                count: existing.count + 1
              });
            } else {
              tagsMap.set(category.name, {
                count: 1,
                href: `/blog/tag/${encodeURIComponent(category.name.toLowerCase())}`,
                id: String(category.id)
              });
            }
          }
        });
      });
      
      // Convert to TaxonomyType array
      const tagsList = Array.from(tagsMap.entries()).map(([name, data]) => ({
        id: data.id,
        name: name,
        href: data.href,
        count: data.count,
        taxonomy: 'tag' as const,
        color: 'indigo' as const
      }));
      
      setAllTags(tagsList);
    }
  }, [posts]);

  const renderCard = (post: PostDataType) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={post.id} className="mb-8" post={post} />;
      default:
        return null;
    }
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages && onPageChange) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePaginationChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const getLatestArticlesText = () => {
    switch (i18n.language) {
      case 'tw':
        return '最新文章';
      case 'cn':
        return '最新文章';
      default:
        return 'Latest Articles';
    }
  };

  const getNoArticlesText = () => {
    switch (i18n.language) {
      case 'tw':
        return '沒有找到文章';
      case 'cn':
        return '没有找到文章';
      default:
        return 'No articles found';
    }
  };

  const getLoadMoreText = () => {
    switch (i18n.language) {
      case 'tw':
        return '加載更多文章';
      case 'cn':
        return '加载更多文章';
      default:
        return 'Load more articles';
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          {heading !== "" && <Heading>{heading || getLatestArticlesText()}</Heading>}
          {posts.length > 0 ? (
            <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
              {posts
            .filter((_, i) => i > 4)
            .map((post) => renderCard(post))}
            </div>
          ) : (
            <div className="py-10 text-center text-neutral-500">
              {getNoArticlesText()}
            </div>
          )}
          
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {totalPages > 1 && (
              <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages} 
                onPageChange={handlePaginationChange} 
              />
            )}
            {currentPage < totalPages && (
              <ButtonPrimary onClick={handleLoadMore}>{getLoadMoreText()}</ButtonPrimary>
            )}
          </div>
        </div>
        {/* <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          <WidgetTags tags={allTags} />
          <WidgetPosts posts={recentPosts} />
        </div> */}
      </div>
    </div>
  );
};

export default SectionLatestPosts;
