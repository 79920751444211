import { FC, useContext, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "context/authContext";
import axiosInstance from "../../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import FileDropzone from "components/FileDropzone/FileDropzone";
import useImageInput from "hooks/useImageInput";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import * as Yup from "yup";

export interface ContractPageProps {
  className?: string;
  contractSuccess?: boolean;
  id?: string;
  key?: string;
}

const ContractUpload: FC<ContractPageProps> = ({
  className = "",
  contractSuccess,
  id,
  key,
}) => {
  const { user, loadingUserData, initialLoading, dispatch } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const {
    previewImage: previewIdCardImage,
    setPreviewImage: setIdCardPreviewImage,
    imageChanged: idCardImageChanged,
    removeImage: removePreviewIdCardImage,
    handleChange: handleIdCardImageChange,
    selectedFile: selectedIDCardImage,
  } = useImageInput("");

  const {
    previewImage: previewCarLicenseImage,
    setPreviewImage: setCarLicensePreviewImage,
    imageChanged: CarLicenseImageChanged,
    removeImage: removeCarLicensePreviewImage,
    handleChange: handleCarLicenseImageChange,
    selectedFile: selectedCarLicenseImage,
  } = useImageInput("");

  const {
    previewImage: previewAddressDocument,
    setPreviewImage: setAddressDocumentPreviewImage,
    imageChanged: addressDocumentChanged,
    removeImage: removeAddressDocumentPreviewImage,
    handleChange: handleAddressDocumentChange,
    fileName: addressDocumentFileName,
    fileType: addressDocumentFileType,
    selectedFile: selectedAddressDocument,
  } = useImageInput("");

  const {
    previewImage: previewDepositDocument,
    setPreviewImage: setDepositDocumentPreviewImage,
    imageChanged: depositDocumentChanged,
    removeImage: removeDepositDocumentPreviewImage,
    handleChange: handleDepositDocumentChange,
    fileName: depositDocumentFileName,
    fileType: depositDocumentFileType,
    selectedFile: selectedDepositDocument,
  } = useImageInput("");

  useEffect(() => {
    const fetchCustomerDocument = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(`/api/v1/customerBookings/${id}`);
        if (res?.data?.carLicense == true) {
          setCarLicenseUploaded(true);
        }
        if (res?.data?.idImage == true) {
          setIdImagUploaded(true);
        }
        if (res?.data?.addressDocument == true) {
          setAddressDocumentUploaded(true);
        }
        // setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (contractSuccess) fetchCustomerDocument();
  }, [contractSuccess]);

  const [apiLoading, setApiLoading] = useState(false);
  const [carLicenseUploaded, setCarLicenseUploaded] = useState(false);
  const [depositDocumentUploaded, setDepositDocumentUploaded] = useState(false);
  const [idImageUploaded, setIdImagUploaded] = useState(false);
  const [addressDocumentUploaded, setAddressDocumentUploaded] = useState(false);
  const { t } = useTranslation("account");

  const validationSchema = Yup.object({
    depositAmount: Yup.number().required("請輸入按金金額"),
    depositMethod: Yup.string().required("請選擇按金付款方式"),
    depositDate: Yup.date().required("請選擇日期"),
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  // useEffect(() => {
  //   if (user) {
  //     if (user?.carLicense == "true") {
  //       setCarLicenseUploaded(true);
  //     }
  //     if (user?.idImage == "true") {
  //       setIdImagUploaded(true);
  //     }
  //     if (user?.addressDocument == "true") {
  //       setAddressDocumentUploaded(true);
  //     }
  //     // formik.setValues({
  //     //   engName: user.engName,
  //     // });
  //   }
  // }, [user, loadingUserData, initialLoading]);

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  const formik = useFormik({
    initialValues: {
      depositAmount: 0,
      depositMethod: "card",
      depositDate: new Date().toISOString().split("T")[0],
    },
    validationSchema,
    onSubmit: async (data: { [key: string]: any }) => {
      setApiLoading(true);
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, (data as any)[key]);
        });

        if (
          !idCardImageChanged ||
          !CarLicenseImageChanged ||
          !addressDocumentChanged
        ) {
          setApiLoading(false);
          setAlert({
            show: true,
            message: "請上傳所有文件!",
            type: "error",
          });
          return;
        }

        // Append the image files to the FormData object
        if (idCardImageChanged) {
          if (previewIdCardImage) {
            // const idCardField = document.querySelector(
            //   "#idImage"
            // ) as HTMLInputElement;
            if (selectedIDCardImage) {
              formData.set("idImage", selectedIDCardImage);
            }
          } else {
            formData.delete("idImage");
          }
        }

        if (CarLicenseImageChanged) {
          if (previewCarLicenseImage) {
            // const carLicenseField = document.querySelector(
            //   "#carLicense"
            // ) as HTMLInputElement;
            if (selectedCarLicenseImage) {
              formData.set("carLicense", selectedCarLicenseImage);
            }
          } else {
            formData.delete("carLicense");
          }
        }

        if (addressDocumentChanged) {
          if (previewAddressDocument) {
            // const addressDocumentField = document.querySelector(
            //   "#addressDocument"
            // ) as HTMLInputElement;
            // console.log(addressDocumentField.files);
            if (selectedAddressDocument) {
              formData.set("addressDocument", selectedAddressDocument);
            }
          } else {
            formData.delete("addressDocument");
          }
        }

        if (depositDocumentChanged) {
          if (previewDepositDocument) {
            if (selectedDepositDocument) {
              formData.set("paymentFiles", selectedDepositDocument);
            }
          } else {
            formData.delete("paymentFiles");
          }
        }

        const res = await axiosInstance.put(
          `/api/v1/customerBookings/customer/${id}`,
          formData
        );
        if (res.status == 200) {
          setApiLoading(false);
          setAlert({
            show: true,
            message: `${t("DOCUMENTUPLOADED", {
              defaultValue: "上傳成功!",
            })}`,
            type: "success",
          });
          // dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
          setIdCardPreviewImage(undefined);
          setAddressDocumentPreviewImage(undefined);
          setCarLicensePreviewImage(undefined);
          setDepositDocumentPreviewImage(undefined);
        }
      } catch (err: any) {
        console.log(err);
        setApiLoading(false);
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0 && formik.isSubmitting) {
      formik.setSubmitting(false);
      const firstErrorKey = errorKeys[0];
      const firstErrorElement =
        document.getElementsByName(firstErrorKey)[0] ||
        document.getElementsByClassName(firstErrorKey)[0];
      if (firstErrorElement) {
        const headerHeight = 120;
        const rect = firstErrorElement.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const topPosition = rect.top + scrollTop - headerHeight;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  const firstErrorRef = useRef(null);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <div className="space-y-6 sm:space-y-8 mt-10">
        {/* HEADING */}
        <h2 className="text-3xl font-semibold text-left">
          {t("UPLOADDOCUMENT", "上傳文件")}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <form onSubmit={formik.handleSubmit} className="">
          <div className="flex items-start justify-between w-full flex-wrap">
            <FileDropzone
              label={t("UPLOADIDCARD", "上傳身份證")}
              fieldName="idImage"
              fileUrl={previewIdCardImage}
              onChange={handleIdCardImageChange}
              removePreviewImage={removePreviewIdCardImage}
              uploaded={idImageUploaded}
            />
            <FileDropzone
              label={t("UPLOADCARLICENSE", "上傳駕駛執照")}
              fieldName="carLicense"
              fileUrl={previewCarLicenseImage}
              onChange={handleCarLicenseImageChange}
              removePreviewImage={removeCarLicensePreviewImage}
              uploaded={carLicenseUploaded}
            />

            <FileDropzone
              label={t("UPLOADADDRESSDOCUMENT", "上傳地址證明文件")}
              fieldName="addressDocument"
              fileUrl={previewAddressDocument}
              onChange={handleAddressDocumentChange}
              fileType={addressDocumentFileType}
              fileName={addressDocumentFileName}
              removePreviewImage={removeAddressDocumentPreviewImage}
              uploaded={addressDocumentUploaded}
            />
            <h2 className="text-3xl font-semibold text-left">按金記錄</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="mb-5 w-full flex gap-2">
              <div className="w-6/12 flex mt-5 flex-col items-start gap-3 ">
                <label
                  className="flex flex-col flex-1 w-full justify-start"
                  ref={formik.errors.depositAmount ? firstErrorRef : null}
                >
                  <span className="text-left text-neutral-800 dark:text-neutral-200 text-xl">
                    按金日期
                  </span>

                  <Input
                    id="depositDate"
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    className="mt-1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.depositDate}
                  />
                  {formik.touched.depositDate && formik.errors.depositDate ? (
                    <p className="text-red-600">
                      {formik.errors.depositDate.toString()}
                    </p>
                  ) : null}
                </label>

                <label
                  className="flex flex-col flex-1 w-full justify-start"
                  ref={formik.errors.depositAmount ? firstErrorRef : null}
                >
                  <span className="text-left text-neutral-800 dark:text-neutral-200 text-xl">
                    按金付款方式
                  </span>

                  <Select
                    id="depositMethod"
                    className="mt-1 w-4/12"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.depositMethod}
                  >
                    <option value=""></option>
                    <option value="card">信用卡</option>
                    <option value="cash">現金</option>

                    <option value="fps">轉數快</option>
                    <option value="wechatpay">WechatPay</option>
                    <option value="alipay">AlipayHK</option>
                  </Select>

                  {formik.touched.depositMethod &&
                  formik.errors.depositMethod ? (
                    <p className="text-red-600">
                      {formik.errors.depositMethod.toString()}
                    </p>
                  ) : null}
                </label>
                <label
                  className="flex flex-col flex-1 w-full justify-start"
                  ref={formik.errors.depositAmount ? firstErrorRef : null}
                >
                  <span className="text-left text-neutral-800 dark:text-neutral-200 text-xl">
                    按金金額
                  </span>

                  <Input
                    id="depositAmount"
                    type="text"
                    className="mt-1 w-6/12"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.depositAmount}
                  />

                  {formik.touched.depositAmount &&
                  formik.errors.depositAmount ? (
                    <p className="text-red-600">
                      {formik.errors.depositAmount.toString()}
                    </p>
                  ) : null}
                </label>
              </div>
              <div className="!text-center flex-1">
                <FileDropzone
                  label={""}
                  fieldName="paymentFiles"
                  fileUrl={previewDepositDocument}
                  onChange={handleDepositDocumentChange}
                  fileType={depositDocumentFileType}
                  fileName={depositDocumentFileName}
                  removePreviewImage={removeDepositDocumentPreviewImage}
                  uploaded={depositDocumentUploaded}
                  classname="lg:w-full mb-0"
                />
              </div>
            </div>
          </div>
          <div className=" mt-10 md:mt-0  max-w-3xl space-y-6 mx-auto">
            <div className="pt-2 text-center">
              <ButtonPrimary
                type="submit"
                disabled={apiLoading}
                className="px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
              >
                提交
                {apiLoading ? (
                  <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                ) : null}
              </ButtonPrimary>
            </div>
            {alert.show && (
              <Alert type={alert.type} message={alert.message} className="" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContractUpload;
