import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism, { Bg2Glassmorphism } from "components/BgGlassmorphism/BgGlassmorphism";
import { fetchBlogPosts } from "utils/fetchBlogPosts";
import { adaptBlogPosts } from "utils/blogAdapter";
import { PostDataType as BlogPostDataType } from "utils/blogAdapter";
import LoadingPage from "shared/LoadingPage/LoadingPage";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import SectionMagazine5 from "./SectionMagazine5";
import { PostDataType as MagazinePostDataType, PostDataType, TaxonomyType } from "data/types";
import WidgetTags from "./WidgetTags";
import WidgetPosts from "./WidgetPosts";
import SectionLatestPosts from "./SectionLatestPosts";

// Adapter function to convert BlogPostDataType to MagazinePostDataType
const adaptPostsForMagazine = (posts: BlogPostDataType[]): MagazinePostDataType[] => {
  return posts.map(post => ({
    id: post.id,
    date: post.date,
    href: post.href,
    categories: post.categories,
    title: post.title,
    featuredImage: post.featuredImage,
    desc: post.desc,
    commentCount: post.commentCount || 0, // Provide default value for optional properties
    viewdCount: post.viewdCount || 0,     // Provide default value for optional properties
    readingTime: post.readingTime,
    postType: post.postType || "standard" // Default to standard if not specified
  }));
};

const BlogPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ tag?: string }>();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<BlogPostDataType[]>([]);
  console.log(posts)
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTag, setActiveTag] = useState<string | null>(null);
  // const [recentPosts, setRecentPosts] = useState<BlogPostDataType[]>([]);
  const [allTags, setAllTags] = useState<TaxonomyType[]>([]);

  // useEffect(() => {
  //   // Fetch popular posts for widget
  //   const loadRecentPosts = async () => {
  //     try {
  //       const response = await fetchBlogPosts(1, 5, 'published');
  //       // Use adaptBlogPosts directly as it returns BlogPostDataType[]
  //       setRecentPosts(adaptBlogPosts(response.data));
  //     } catch (error) {
  //       console.error("Error loading recent posts:", error);
  //     }
  //   };

  //   if (posts.length > 0) {
  //     loadRecentPosts();
      
  //     // Extract all unique tags from posts
  //     const tagsMap = new Map<string, {count: number, href: string, id: string}>();
      
  //     posts.forEach(post => {
  //       post.categories.forEach(category => {
  //         if (category.taxonomy === 'tag') {
  //           const existing = tagsMap.get(category.name);
  //           if (existing) {
  //             tagsMap.set(category.name, {
  //               ...existing, 
  //               count: existing.count + 1
  //             });
  //           } else {
  //             tagsMap.set(category.name, {
  //               count: 1,
  //               href: `/blog/tag/${encodeURIComponent(category.name.toLowerCase())}`,
  //               id: String(category.id)
  //             });
  //           }
  //         }
  //       });
  //     });
      
  //     // Convert to TaxonomyType array
  //     const tagsList = Array.from(tagsMap.entries()).map(([name, data]) => ({
  //       id: data.id,
  //       name: name,
  //       href: data.href,
  //       count: data.count,
  //       taxonomy: 'tag' as const,
  //       color: 'indigo' as const
  //     }));
      
  //     setAllTags(tagsList);
  //   }
  // }, [posts]);


  // Process the URL to extract tag if present
  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/tag/')) {
      const tagFromUrl = decodeURIComponent(path.split('/tag/')[1]);
      setActiveTag(tagFromUrl);
    } else {
      setActiveTag(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    const loadBlogPosts = async () => {
      setLoading(true);
      try {
        // If we have an active tag, filter by tag
        const response = await fetchBlogPosts(currentPage, 10, 'published', activeTag);
        setPosts(adaptBlogPosts(response.data));
        setTotalPages(response.totalPages);
      } catch (error) {
        console.error("Error loading blog posts:", error);
      } finally {
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, [currentPage, activeTag]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get page title based on current language
  const getPageTitle = () => {
    switch (i18n.language) {
      case 'tc':
        return '租車博客 | GoodWayPass';
      case 'sc':
        return '租车博客 | GoodWayPass';
      default:
        return 'Car Rental Blog | GoodWayPass';
    }
  };

  // Get page description based on current language
  const getPageDescription = () => {
    switch (i18n.language) {
      case 'tc':
        return activeTag 
          ? `關於${activeTag}的所有文章和指南 - GoodWayPass租車博客` 
          : '在GoodWayPass租車博客閱讀最新的租車指南、旅遊提示和汽車資訊';
      case 'sc':
        return activeTag 
          ? `关于${activeTag}的所有文章和指南 - GoodWayPass租车博客` 
          : '在GoodWayPass租车博客阅读最新的租车指南、旅游提示和汽车资讯';
      default:
        return activeTag 
          ? `All articles and guides about ${activeTag} - GoodWayPass Car Rental Blog` 
          : 'Read the latest car rental guides, travel tips, and automotive information at GoodWayPass Blog';
    }
  };

  // Get heading based on current language
  const getHeading = () => {
    if (activeTag) {
      switch (i18n.language) {
        case 'tc':
          return `${activeTag} 相關文章`;
        case 'sc':
          return `${activeTag} 相关文章`;
        default:
          return `Articles about ${activeTag}`;
      }
    }

    switch (i18n.language) {
      case 'tc':
        return '租車及養車知識';
      case 'sc':
        return '租车及养车知识';
      default:
        return 'Car Rental and Car Maintenance News';
    }
  };

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
        {activeTag && <meta name="keywords" content={`${activeTag}, car rental, GoodWayPass`} />}
        <link rel="canonical" href={`https://goodwaypass.com.hk/${location.pathname}`} />
      </Helmet>
      <Bg2Glassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      <div className="container relative">
        <div className="pt-10 pb-16 lg:pb-28 lg:pt-20">
          <div className="mb-12">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-neutral-900 dark:text-neutral-100">
              {getHeading()}
            </h1>
            {activeTag && (
              <button 
                onClick={() => navigate('/blog')}
                className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {i18n.language === 'tc' ? '查看所有文章' : i18n.language === 'sc' ? '查看所有文章' : 'View all articles'}
              </button>
            )}
          </div>

          {/* Main Content + Sidebar Layout */}
          <div className="flex flex-col lg:flex-row">
            {/* Main Content */}
            <div className="w-full lg:w-3/4 lg:pr-10">
              {loading ? (
                <div className="flex justify-center items-center min-h-[50vh]">
                  <LoadingPage />
                </div>
              ) : (
                <>
                  <div className="pb-8">
                    <SectionMagazine5 posts={adaptPostsForMagazine(posts)} />
                  </div>
                  
                  {/* Pagination */}
                  {totalPages > 1 && (
                    <div className="flex justify-center mt-10">
                      <nav className="nc-Pagination inline-flex items-center space-x-1 text-base font-medium">
                        {Array.from({ length: totalPages }).map((_, i) => (
                          <button
                            key={i}
                            className={`w-11 h-11 flex items-center justify-center rounded-full ${
                              currentPage === i + 1
                                ? "bg-primary-6000 text-white"
                                : "bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-900"
                            }`}
                            onClick={() => handlePageChange(i + 1)}
                          >
                            {i + 1}
                          </button>
                        ))}
                      </nav>
                    </div>
                  )}
                </>
              )}
            </div>

       
          </div>
          {loading ? (
            <div className="flex justify-center items-center min-h-[50vh]">
              <LoadingPage />
            </div>
          ) : (
              
            <SectionLatestPosts 
              className="pN-8" 
              posts={posts} 
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              heading=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
          