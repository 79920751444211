import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
import LoadingPage from "shared/LoadingPage/LoadingPage";
import { fetchBlogPostBySlug, fetchRelatedPosts, BlogPost } from "utils/fetchBlogPosts";
import { adaptBlogPosts, PostDataType } from "utils/blogAdapter";
import Card3 from "./Card3";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";

const BlogSingle = () => {
  // Use slug for SEO-friendly URLs 
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState<BlogPost | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<PostDataType[]>([]);
  const [error, setError] = useState<string | null>(null);

  console.log(post)

  useEffect(() => {
    const loadBlogPost = async () => {
      if (!slug) return;
      
      console.log('[DEBUG] Loading blog post with slug:', slug);
      
      setLoading(true);
      try {
        // Get the post by slug
        console.log('[DEBUG] Fetching post by slug:', slug);
        const postData = await fetchBlogPostBySlug(slug);
        
        if (postData) {
          console.log('[DEBUG] Post found:', postData.title);
          console.log('[DEBUG] Post slug from DB:', postData.slug);
          console.log('[DEBUG] Full post data:', JSON.stringify(postData, null, 2));
          setPost(postData);
          
          // Fetch related posts
          const relatedData = await fetchRelatedPosts(postData._id, 3);
          setRelatedPosts(adaptBlogPosts(relatedData));
        } else {
          console.error('[DEBUG] Post not found with slug:', slug);
          console.error('[DEBUG] URL path:', window.location.pathname);
          // Try to decode the slug in case it has URL encoding issues
          const decodedSlug = decodeURIComponent(slug);
          console.log('[DEBUG] Decoded slug:', decodedSlug);
          if (decodedSlug !== slug) {
            console.log('[DEBUG] Trying with decoded slug...');
            const postDataWithDecoded = await fetchBlogPostBySlug(decodedSlug);
            if (postDataWithDecoded) {
              console.log('[DEBUG] Post found with decoded slug!');
              setPost(postDataWithDecoded);
              
              // Fetch related posts
              const relatedData = await fetchRelatedPosts(postDataWithDecoded._id, 3);
              setRelatedPosts(adaptBlogPosts(relatedData));
              return;
            }
          }
          
          setError("Blog post not found");
        }
      } catch (err) {
        console.error("[DEBUG] Error loading blog post:", err);
        setError("Failed to load blog post");
      } finally {
        setLoading(false);
      }
    };

    loadBlogPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <LoadingPage />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="container my-24 text-center">
        <h2 className="text-3xl font-semibold mb-6">{error || "Blog post not found"}</h2>
        <Link to="/blog">
          <ButtonPrimary>Back to Blog</ButtonPrimary>
        </Link>
      </div>
    );
  }

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
        { post.tags && post.tags?.length > 0 && (
          <div className="my-3">
            <CategoryBadgeList  categories={post.tags.map(tag => ({
              id: tag,
              name: tag,
              href: `/blog/tag/${tag}`,
              taxonomy: 'tag',
              color: 'purple'
            }))} />
          </div>
        )}
        
          <h1
            className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl"
          >
            {post.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {post.summary}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
              />
              <div className="ml-3">
        
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {new Date(post.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {post.readTime || 5} min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    );
  };

  const renderTags = () => {
    if (!post.tags || post.tags.length === 0) return null;
    console.log(post.tags)
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {post.tags.map((tag) => (
          <a
            key={tag}
            className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
            href={`/blog/tag/${tag}`}
          >
            {tag}
          </a>
        ))}
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITTEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <Link to="/">{post.author || "Anonymous"}</Link>
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const renderRelatedPosts = () => {
    if (relatedPosts.length === 0) return null;
    
    return (
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-28">
        <div className="container">
          <h2 className="text-3xl font-semibold mb-10 md:mb-12 text-neutral-900 dark:text-neutral-100 text-center">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8">
            {relatedPosts.map((post) => (
              <Card3 key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-BlogSingle pt-8 lg:pt-16">
      <Helmet>
        <title>{post.metaTitle || post.title} | GoodWayPass</title>
        <meta name="description" content={post.metaDescription || post.summary} />
      </Helmet>
      
      {post.featuredImage && (
        <div className="container rounded-xl">
          <div className="mx-auto max-w-screen-md relative">
            <div className="aspect-w-16 aspect-h-11 md:aspect-h-11 rounded-xl overflow-hidden">
              <NcImage
                containerClassName="absolute inset-0"
                src={post.featuredImage}
                alt={post.altText || post.title}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      )}

      <div className="nc-SingleContent container space-y-10">
        <div className="space-y-10 lg:space-y-14">
          {renderHeader()}
          {renderContent()}
        </div>
        <div className="space-y-12 lg:space-y-16 pt-5">
          {renderTags()}
          {/* {renderAuthor()} */}
        </div>
      </div>
      
      {renderRelatedPosts()}
    </div>
  );
};

export default BlogSingle;
