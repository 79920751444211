import axiosInstance from "../utils/axiosInstance";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { motion } from "framer-motion";
import Badge from "shared/Badge/Badge";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Loading from "components/Loading/loading";
import Page404 from "containers/Page404/Page404";
export interface PayPageProps {
  className?: string;
}

export interface bookingProps {
  pickUpDate: string;
  pickupLocation?: string;
  returnDate: string;
  returnLocation?: string;
  status?: string;
  total?: number;
  bookingId?: number;
  car?: carProps;
  paymentMethod?: string;
  paymentStatus?: string;
  rentalFee?: number;
  carCoverCheck?: boolean;
  carCoverTotal?: number;
}

export interface carProps {
  brand?: string;
  code?: string;
  color?: string;
  doors?: string;
  model?: string;
  featuredImage?: string;
  seats?: string;
  year?: string;
  gearbox?: string;
  isBoat?: boolean;
}

interface Locations {
  csw: string;
  hki: string;
  kowloon: string;
  nt: string;
  airport: string;
  [key: string]: string; // this is an index signature
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const [booking, setBooking] = useState<bookingProps>();
  const [accountPage, setAccountPage] = useState<boolean>(false);
  const [orderStatus, setOrderStatus] = useState("loading");
  const location = useLocation();
  const { t } = useTranslation("paydone");

  useEffect(() => {
    setOrderStatus("loading");
    const queryParams = new URLSearchParams(location.search);
    const bookingId = queryParams.get("bookingId");
    const secretKey = queryParams.get("key");
    const account = queryParams.get("account");
    if (account) {
      setAccountPage(true);
    }

    if (!secretKey || !bookingId) {
      setOrderStatus("failed");
    }

    const fetchBooking = async () => {
      try {
        const res = await axiosInstance.get(
          `/api/v1/bookings/pay-done?bookingId=${bookingId}&key=${secretKey}`
        );

        // Parse the date string into a moment object and convert it to Hong Kong time
        let pickUpDate = moment(res.data.pickUpDate).tz("Asia/Hong_Kong");
        let returnDate = moment(res.data.returnDate).tz("Asia/Hong_Kong");

        // Format the moment objects back into ISO strings
        res.data.pickUpDate = pickUpDate.toISOString();
        res.data.returnDate = returnDate.toISOString();

        // Then you can set the state with the updated res.data object
        setBooking(res.data);
        if (!res.data) {
          setOrderStatus("failed");
        } else {
          setOrderStatus("success");
        }
      } catch (error) {
        setOrderStatus("failed");
        console.error(error);
      }
    };

    fetchBooking();
  }, [location]);
  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const locations = {
    csw: t("LOCATION1", "九龍城廣場416號店鋪"),
    hki: t("LOCATION2", "港島送車上門 (+$500)"),
    kowloon: t("LOCATION3", "九龍送車上門 (+$300)"),
    nt: t("LOCATION4", "新界送車上門 (+$500)"),
    airport: t("LOCATION5", "機場送車上門 (+$700)"),
    kwuntong: t("BLOCATION1", "觀塘"),
    central: t("BLOCATION2", "中環"),
    tst: t("BLOCATION3", "尖沙咀"),
    saikung: t("BLOCATION4", "西貢"),
  };

  const paymentMethods = {
    card: t("CARD", "信用卡"),
    fps: t("FPS", "FPS 轉數快"),
    alipay: t("ALIPAY", "Alipay"),
    wechatpay: t("WECHATPAY", "WeChatPay"),
  };

  const renderContent = () => {
    return orderStatus == "loading" ? (
      <Loading className="min-h-[50rem]" />
    ) : orderStatus == "success" ? (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {accountPage
            ? `${t("BOOKING", "訂單號碼")}: #${booking?.bookingId} `
            : booking?.car?.isBoat
            ? t("BCONGRATULATION", "成功預約遊艇 🎉")
            : t("CONGRATULATION", "成功預約車輛 🎉")}
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            {t("YOURBOOKING", "您的訂單")}
          </h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={booking?.car?.featuredImage} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {booking?.car?.brand}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {booking?.car?.model}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {booking?.car?.doors}{" "}
                {booking?.car?.doors && `${t("DOORS", "門")} · `}{" "}
                {booking?.car?.isBoat
                  ? ""
                  : booking?.car?.gearbox == "Automatic"
                  ? t("AUTOGEARBOX", "自動波")
                  : t("MANUALGEARBOX", "棍波")}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-600 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-600">
                  {booking?.car?.isBoat
                    ? t("common:BOATPICKUPTIME", "上船時間")
                    : t("PICKUPDATE", "取車日期")}
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {booking &&
                    new Date(booking.pickUpDate).toLocaleDateString(
                      currentLanguage == "en" ? "en-US" : "zh-HK"
                    )}{" "}
                  {booking &&
                    booking.pickUpDate &&
                    new Date(booking.pickUpDate)
                      .getHours()
                      .toString()
                      .padStart(2, "0")}
                  {booking && booking.pickUpDate && ":"}
                  {booking &&
                    booking.pickUpDate &&
                    new Date(booking.pickUpDate)
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-600 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-600">
                  {booking?.car?.isBoat
                    ? t("common:BOATRETURNTIME", "落船時間")
                    : t("RETURNDATE", "還車日期")}
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {booking &&
                    new Date(booking.returnDate).toLocaleDateString(
                      currentLanguage == "en" ? "en-US" : "zh-HK"
                    )}{" "}
                  {booking &&
                    booking.returnDate &&
                    new Date(booking.returnDate)
                      .getHours()
                      .toString()
                      .padStart(2, "0")}
                  {booking && booking.returnDate && ":"}
                  {booking &&
                    booking.returnDate &&
                    new Date(booking.returnDate)
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}
                </span>
              </div>
            </div>
          </div>
        </div>

        {booking?.paymentMethod !== "card" && (
          <div className="space-y-6 pb-10">
            <h3 className="text-2xl font-semibold">
              {t("PAYMENTMETHOD", "付款方式")}
            </h3>
            <div className="pb-4">
              {t(
                "TRANSFERMESSAGE",
                "轉賬後，請將轉賬証明 Whatsapp 6510 7107！ (附上訂單號碼及付款人全名以作核對款項)"
              )}
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center py-5">
              <div className="w-full lg:w-6/12 lg:pr-2 text-center">
                <div className="font-medium text-[18px] pb-2">
                  FPS / Tap & Go
                </div>
                <NcImage
                  src={"/emailimage/fps.jpeg"}
                  className="max-h-[300px] mx-auto"
                />
              </div>
              <div className="w-full lg:w-6/12 lg:pl-2 text-center pt-3 lg:pt-0">
                <div className="font-medium text-[18px] pb-2">
                  Alipay / WeChatPay
                </div>
                <NcImage
                  src={"/emailimage/alipay.jpeg"}
                  className="max-h-[300px] mx-auto"
                />
              </div>
            </div>
          </div>
        )}

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            {t("BOOKINGDETAIL", "訂單詳情")}
          </h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">{t("BOOKINGID", "訂單號碼")}</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                #{booking?.bookingId}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">{t("BOOKINGSTATUS", "訂單狀態")}</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.status && (
                  <Badge
                    name={
                      booking?.status == "Pending"
                        ? t("STATUSPENDING", "待處理")
                        : booking?.status == "Paid"
                        ? t("PAID", "已付款")
                        : booking?.status == "Cancelled"
                        ? t("STATUSCANCELLED", "已取消")
                        : booking?.status == "Confirmed"
                        ? t("STATUSCONFIRMED", "已確認")
                        : ""
                    }
                    color={
                      booking?.status == "Pending"
                        ? "yellow"
                        : booking?.status == "Paid"
                        ? "green"
                        : booking?.status == "Cancelled"
                        ? "red"
                        : booking?.status == "Confirmed"
                        ? "green"
                        : "gray"
                    }
                  />
                )}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {booking?.car?.isBoat
                  ? t("BOATPICKUPLOCATION", "上船地點") || "上船地點"
                  : t("PICKUPLOCATION", "取車地點") || "Pickup Location"}
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.pickupLocation
                  ? locations[booking?.pickupLocation as keyof typeof locations]
                  : ""}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {booking?.car?.isBoat
                  ? t("BOATRETURNLOCATION", "落船地點") || "落船地點"
                  : t("RETURNLOCATION", "還車地點") || "Return Location"}
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.returnLocation
                  ? locations[booking?.returnLocation as keyof typeof locations]
                  : ""}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {booking?.car?.isBoat
                  ? t("RENTBOAT", "租船費用")
                  : t("RENTALFEE", "租車費用")}
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                ${booking?.rentalFee}
              </span>
            </div>

            {booking?.carCoverCheck && (
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">
                  {booking?.carCoverCheck &&
                    booking?.carCoverTotal &&
                    booking?.carCoverTotal > 0 &&
                    t("CARCOVERPRICE", "全車保費用")}
                </span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  ${booking?.carCoverTotal}
                </span>
              </div>
            )}

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">{t("TOTAL", "總共")}</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                ${booking?.total}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">{t("PAYMENTMETHOD", "付款方式")}</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.paymentMethod
                  ? paymentMethods[
                      booking?.paymentMethod as keyof typeof paymentMethods
                    ]
                  : ""}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">{t("PAYMENTSTATUS", "付款狀態")}</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.paymentStatus && (
                  <Badge
                    name={
                      booking?.paymentStatus == "PENDING"
                        ? t("PENDING", "未付款")
                        : booking?.paymentStatus == "SUCCEEDED"
                        ? t("SUCCEEDED", "已付款")
                        : booking?.paymentStatus == "CONFIRMED"
                        ? t("CONFIRMED", "已確認")
                        : ""
                    }
                    color={
                      booking?.paymentStatus == "PENDING"
                        ? "yellow"
                        : booking?.paymentStatus == "SUCCEEDED"
                        ? "green"
                        : booking?.paymentStatus == "CONFIRMED"
                        ? "green"
                        : "gray"
                    }
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div>
          {accountPage ? (
            <ButtonPrimary href="/account-bookings">
              {t("BACKTOMYBOOKING", "返回我的訂單")}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary href="/car-rental">
              {t("EXPLOREMORECARS", "瀏覽更多車輛")}
            </ButtonPrimary>
          )}
        </div>
      </div>
    ) : (
      <Page404 />
    );
  };

  return (
    <motion.div
      className={`nc-PayPage ${className}`}
      data-nc-id="PayPage"
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </motion.div>
  );
};

export default PayPage;
