import { FC } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
// import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
// import "./App.css";

export interface PageContactProps {
  className?: string;
}

export const ContactForm = () => {
  const { t } = useTranslation("home");
  const info = [
    {
      title: `🗺 ${t("ADDRESS", "地址")}`,
      desc: `${t(
        "REALADDRESS",
        "長勝租車位於九龍城廣場4樓416辦公室 | 5、6/F停車場 B3維修中心 | 九龍塘延文禮士道22號地庫停車場全層"
      )}`,
      desc2: `${t("ADDRESS2", "地址2")}`,
    },
    {
      title: `🕙 ${t("AVAILABLEHOURS", "營業時間")}`,
      desc: `${t("OPENDATES", "10:00 – 20:00 | 星期一至日")}`,
    },
    {
      title: `☎ ${t("PHONE", "電話")}`,
      desc: "+852 65 107 107",
    },
  ];

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyCGeXmNiZ3HsRIKTOsCO47s-AcrjCz_KXA",
  // });
  const center = useMemo(() => ({ lat: 22.3394647, lng: 114.1557429 }), []);

  return (
    <div className="mb-24 lg:mb-32">
      <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {t("CONTACT", "聯絡我們")}
      </h2>
      <div className="container max-w-7xl mx-auto">
        <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
          <div className="space-y-8">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                 
                </span>
                {item.desc2 && (
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      {item.desc2}
                    </span>
                  )}
              </div>
            ))}
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🌏 {t("SOCIALS", "社交媒體")}
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>
          <div className="h-[100%] w-[100%]">
            {/* {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={18}
              >
                <Marker
                  position={{ lat: 22.3394647, lng: 114.1557429 }}
                  label={"長勝租車"}
                  title={"長勝租車"}
                />
              </GoogleMap>
            )} */}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14761.500037161475!2d114.1557429!3d22.3394647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340407add291017f%3A0x8336cebdc5db3db6!2z6ZW35Yud56ef6LuKIOW4guS4reW_g-Wbm-WxpOW6l-iIliDlpJrmrL7lkI3ou4roh6rpp5XpgYrpppbpgbg!5e0!3m2!1szh-TW!2shk!4v1695744758547!5m2!1szh-TW!2shk"
              height="450"
              style={{ border: 0, width: "100%" }}
              // allowFullScreen=
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.59939085868!2d114.18796239999999!3d22.3309857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404072b0657e891%3A0x581f6f175c9a655a!2z5Lmd6b6N5Z-O5buj5aC0!5e0!3m2!1szh-TW!2shk!4v1740245577558!5m2!1szh-TW!2shk"
              height="450"
              style={{ border: 0, width: "100%" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.div
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>聯絡我們 - 長勝租車 GoodwayPass Car Rental </title>
      </Helmet>
      <ContactForm />
    </motion.div>
  );
};

export default PageContact;
