import axios from 'axios';
import axiosInstance from './axiosInstance';

const API_BASE_URL =  '/api/v1';

export interface BlogPost {
  _id: string;
  title: string;
  slug: string;
  metaTitle?: string;
  metaDescription?: string;
  summary: string;
  content: string;
  featuredImage?: string;
  altText?: string;
  tags?: string[];
  status: 'draft' | 'published';
  author?: string;
  language?: string;
  publishDate?: string;
  canonical?: string;
  readTime?: number;
  createdAt: string;
  updatedAt: string;
}

export interface BlogResponse {
  success: boolean;
  data: BlogPost[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export const fetchBlogPosts = async (
  page: number = 1, 
  limit: number = 10, 
  status: string = 'published',
  tag?: string | null
): Promise<BlogResponse> => {
  try {
    let url = `/api/v1/blogs?page=${page}&limit=${limit}&status=${status}`;
    
    // Add tag filter if provided
    if (tag) {
      url += `&tag=${encodeURIComponent(tag)}`;
    }
    
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw error;
  }
};

export const fetchBlogPostById = async (id: string): Promise<BlogPost | null> => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/blogs/${id}`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching blog post with ID ${id}:`, error);
    return null;
  }
};

export const fetchBlogPostBySlug = async (slug: string): Promise<BlogPost | null> => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/blogs/slug/${slug}`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching blog post with slug ${slug}:`, error);
    return null;
  }
};

export const fetchBlogCategories = async (): Promise<string[]> => {
  try {
    // This should be updated if you add a dedicated endpoint for categories
    const response = await axiosInstance.get(`${API_BASE_URL}/blogs/categories`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blog categories:', error);
    return [];
  }
};

export const fetchRelatedPosts = async (postId: string, limit = 3): Promise<BlogPost[]> => {
  try {
    // Use post ID for related posts since we need something to find similarity
    const response = await axiosInstance.get(`${API_BASE_URL}/blogs/related/${postId}?limit=${limit}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching related posts:', error);
    return [];
  }
}; 