import { BlogPost as FetchedBlogPost } from './fetchBlogPosts';
import { TaxonomyType } from 'data/types';

// Define what a tag might look like in your data
interface TagItem {
  type?: string;
  [key: string]: any;
}

// Define the BlogPost interface to include the correct tags type
export interface BlogPost {
  _id: string;
  title: string;
  summary: string;
  content: string;
  featuredImage?: string;
  slug?: string;
  author?: string;
  tags: string[] | TagItem[] | string; // Tags can be array of strings, array of objects, or a JSON string
  status: 'draft' | 'published';
  createdAt: string;
  updatedAt: string;
  publishDate?: string;
  readTime?: number;
  metaTitle?: string;
  metaDescription?: string;
}

// Define the PostDataType that the components expect
export interface PostDataType {
  id: string;
  title: string;
  href: string;
  desc?: string;
  featuredImage: string;
  date: string;
  categories: TaxonomyType[];
  author: {
    id: string;
    name: string;
    href: string;
    avatar: string;
  };
  readingTime: number;
  postType: 'standard' | 'video' | 'gallery' | 'audio';
  commentCount?: number;
  viewdCount?: number;
  slug?: string;
}

// Helper function to deeply parse nested JSON strings
const deepParseJson = (value: any): any => {
  if (typeof value !== 'string') return value;
  
  try {
    const parsed = JSON.parse(value);
    // If it still looks like a stringified value, parse deeper
    if (typeof parsed === 'string' && 
        (parsed.startsWith('[') || parsed.startsWith('{') || parsed.startsWith('"'))) {
      return deepParseJson(parsed);
    }
    // If it's an array, try to parse each element
    if (Array.isArray(parsed)) {
      return parsed.map(item => deepParseJson(item));
    }
    return parsed;
  } catch (e) {
    // Not JSON, return as is
    return value;
  }
};

// Enhance the extractTags function to handle nested JSON strings better
const extractTags = (tagsData: any): string[] => {
  // Handle null or undefined
  if (!tagsData) return [];
  
  // Check for nested stringified arrays (the problematic case)
  if (typeof tagsData === 'string') {
    try {
      // Try to parse it
      const parsed = JSON.parse(tagsData);
      
      // If successful and it's an array, try to extract from each item
      if (Array.isArray(parsed)) {
        return parsed.map(item => {
          // Handle nested stringified items
          if (typeof item === 'string' && (item.startsWith('[') || item.startsWith('"'))) {
            try {
              return JSON.parse(item);
            } catch (e) {
              return item;
            }
          }
          return item;
        }).flat();
      }
      
      // If it's not an array, return it as a single item array
      return [parsed.toString()];
    } catch (e) {
      // If parsing fails, treat it as a single tag
      return [tagsData];
    }
  }
  
  // If it's an array, process each item
  if (Array.isArray(tagsData)) {
    return tagsData.flatMap(tag => {
      // For objects, try to extract the tag value
      if (typeof tag === 'object' && tag !== null) {
        // Return the type property or stringify the object
        return tag.type || JSON.stringify(tag);
      }
      // For nested arrays or strings, extract recursively
      if (Array.isArray(tag) || (typeof tag === 'string' && 
          (tag.startsWith('[') || tag.startsWith('{') || tag.startsWith('"')))) {
        return extractTags(tag);
      }
      // Otherwise return as string
      return String(tag);
    });
  }
  
  // If it's an object, try to get the type property
  if (typeof tagsData === 'object' && tagsData !== null) {
    return tagsData.type ? [String(tagsData.type)] : [JSON.stringify(tagsData)];
  }
  
  // Default fallback
  return [String(tagsData)];
};

// Convert a BlogPost from the API to the format expected by components
export const adaptBlogPost = (post: FetchedBlogPost): PostDataType => {
  
  // Use the helper function to extract clean tags
  const tagArray = extractTags(post.tags);
  
  // Now convert the clean tagArray to categories format
  const categories = tagArray.map((tag, index) => ({
    id: `tag-${index}`,
    name: tag,
    href: `/blog/tag/${encodeURIComponent(tag.toLowerCase())}`,
    taxonomy: 'tag' as const,
    color: 'indigo' as const
  }));

  // Format the date
  const date = new Date(post.createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  
  // Create SEO-friendly slug from title if not available
  const slug = post.slug || post.title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/^-+|-+$/g, '');

  // Generate SEO-friendly URL (just the slug)
  const postUrl = `/blog/${slug}`;

  return {
    id: post._id,
    title: post.title,
    href: postUrl,
    desc: post.summary,
    featuredImage: post.featuredImage || '/placeholder-image.jpg',
    date,
    categories,
    author: {
      id: '1',
      name: post.author || 'Anonymous',
      href: '/author',
      avatar: '/avatar-placeholder.jpg',
    },
    readingTime: post.readTime || 5,
    postType: 'standard',
    commentCount: 0,
    viewdCount: 0,
    slug
  };
};

// Convert an array of BlogPosts to an array of PostDataType
export const adaptBlogPosts = (posts: FetchedBlogPost[]): PostDataType[] => {
  return posts.map(post => adaptBlogPost(post));
}; 