import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// import {  PHOTOS } from "./product/constant";
import Badge from "shared/Badge/Badge";
import SectionDateRange from "./product/SectionDateRange";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "./product/Layout";
import useFetch from "hooks/useFetch";
import { CarDataType } from "data/types";
import { SearchContext } from "context/searchContext";
import RentalCarDatesRangeInput from "components/HeroSearchForm/(car-search-form)/RentalCarDatesRangeInput";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { AuthContext } from "context/authContext";
import axiosInstance from "../utils/axiosInstance";
import { motion } from "framer-motion";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import ModalSelectDate from "components/ModalSelectDate";
import { format, getHours, getMinutes, isAfter } from "date-fns";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet-async";
export interface FetchProductDetail {
  data?: CarDataType | any;
  loading?: boolean;
  error?: any;
}

interface imageType {
  original: string;
  thumbnail: string;
}

const ProductDetail: FC<{}> = (props: any) => {
  // USE STATE
  const { user } = useContext(AuthContext);
  const [boatPlan, setBoatPlan] = useState(1);
  const [priceList, setPriceList] = useState<any[]>([]);
  const [start, setStart] = useState<any>();
  const [end, setEnd] = useState<any>();
  const [startTime, setStartTime] = useState<Date | any>();
  const [endTime, setEndTime] = useState<Date | any>();
  const [extraFee, setExtraFee] = useState(0);
  const [unavailableDates, setUnavailableDates] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [rentalDays, setRentalDays] = useState<number>(0);
  const [productImages, setProductImages] = useState<imageType[]>([]);
  const [maxDateDatepicker, setMaxDateDatepicker] = useState<any>(null);
  const [minDateDatepicker, setMinDateDatepicker] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
    return tomorrow;
  });

  const thisPathname = useLocation().pathname;
  const productId = thisPathname.split("-").pop(); // This gets the last part after the last '-'

  const navigate = useNavigate();
  const { t } = useTranslation("product");
  const { data, loading, error }: FetchProductDetail = useFetch(
    `/api/v1/cars/${productId}`
  );

  const unavailableDatesFetch: FetchProductDetail = useFetch(
    `/api/v1/cars/status/${productId}`
  );

  const [wishlists, setWishList] = useState<string[]>([]);
  const { i18n } = useTranslation();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    value: number,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center cursor-pointer">
        <input
          checked={boatPlan === value}
          id={id + name}
          name={name}
          type="radio"
          onChange={() => {
            setBoatPlan(value);
            calculatePrice(start, end, startTime, endTime, value);
          }}
          className="focus:ring-gray-500 h-6 w-6 text-gray-500 border-neutral-300 !checked:bg-gray-500 bg-transparent cursor-pointer"
          disabled
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300 cursor-pointer"
        >
          {label}
        </label>
      </div>
    );
  };

  // useEffect(() => {
  //   if (unavailableDatesFetch.data) {
  //     const updateUnavailableDates =
  //       unavailableDatesFetch.data?.length > 1
  //         ? unavailableDatesFetch.data?.filter((date: any) => {
  //             const pickUpDate = moment(date.pickUpDate)
  //               .tz("Asia/Hong_Kong")
  //               .startOf("day");
  //             const returnDate = moment(date.returnDate)
  //               .tz("Asia/Hong_Kong")
  //               .endOf("day");
  //             return {
  //               pickUpDate,
  //               returnDate,
  //             };
  //           })
  //         : unavailableDatesFetch.data;
  //     setUnavailableDates(updateUnavailableDates);
  //   }
  // }, [unavailableDatesFetch]);

  useEffect(() => {
    if (unavailableDatesFetch.data) {
      const updateUnavailableDates =
        unavailableDatesFetch.data?.length > 0
          ? unavailableDatesFetch.data?.map((date: any) => {
              const pickUpDate = moment(date.pickUpDate).tz("Asia/Hong_Kong");
              const returnDate = moment(date.returnDate).tz("Asia/Hong_Kong");
              // if (pickUpDate.hour() === 10) {
              //   pickUpDate.startOf("day"); // Set pick-up time to the start of the day
              // }
              // if (returnDate.hour() === 20) {
              //   returnDate.endOf("day"); // Set return time to the end of the day
              // }

              return {
                pickUpDate: pickUpDate.toDate(),
                returnDate: returnDate.toDate(),
              };
            })
          : unavailableDatesFetch.data;
      setUnavailableDates(updateUnavailableDates);
    }
  }, [unavailableDatesFetch.data]);

  useEffect(() => {
    if (data?.price?.price) {
      setPriceList(data?.price.price);
    }
    if (startDate && endDate && startTime && endTime) {
      calculatePrice(startDate, endDate, startTime, endTime);
    }
    const firstImage = data?.featuredImage
      ? {
          original: data.featuredImage,
          thumbnail: data.featuredImage,
          originalHeight: 300,
        }
      : null; // Use null instead of an empty object for no featuredImage

    const otherImages = data?.galleryImages
      ? data.galleryImages.map((image: string) => ({
          original: image,
          thumbnail: image,
          originalHeight: 300,
        }))
      : [];

    setProductImages(
      [firstImage, ...otherImages].filter((image) => image !== null)
    );
  }, [data]);
  // const handleOpenModalImageGallery = () => {
  //   router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  // };

  const { state, dispatch } = useContext(SearchContext);
  const { startDate, endDate, pickUpLocation, returnLocation } = state;

  useEffect(() => {
    const fetchWishList = async () => {
      try {
        const res = await axiosInstance.get(
          `/api/v1/users/getcarid/${user._id}`
        );
        setWishList(res.data.carList);
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      fetchWishList();
    }
  }, [user]);

  // const calculateDateDifference = (
  //   start: Date | null,
  //   end: Date | null
  // ): number => {
  //   if (!start || !end) {
  //     return 0;
  //   }
  //   if (start.getTime() == end.getTime()) {
  //     return 1;
  //   }
  //   const diffInMilliseconds = end.getTime() - start.getTime();
  //   const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
  //   return diffInDays;
  // };

  const mergeDateAndTime = (
    date: Date | null,
    time: Date | null
  ): Date | null => {
    if (!date || !time) return null;

    const merged = new Date(date);
    merged.setHours(time.getHours(), time.getMinutes(), 0, 0);
    return merged;
  };
  const calculateDateDifferenceWithHours = (
    start: Date | null,
    end: Date | null
  ): { days: number; hours: number } => {
    if (!start || !end) {
      return { days: 0, hours: 0 };
    }

    const diffInMilliseconds = end.getTime() - start.getTime();
    let diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    let days = Math.floor(diffInHours / 24);
    let hours = Math.floor(diffInHours % 24);

    // If the end time is earlier than the start time, treat it as a full day.
    if (end.getHours() < start.getHours()) {
      hours = 0;
      days += 1;
    }

    return { days, hours };
  };
  const calculatePrice = useCallback(
    (
      starts: Date | null,
      ends: Date | null,
      startTime: Date | null,
      endTime: Date | null,
      pricePlan = boatPlan
    ) => {
      if (!data?.isBoat) {
        if (data?.price?.price[1]) {
          const startMerged = mergeDateAndTime(starts, startTime);
          const endMerged = mergeDateAndTime(ends, endTime);
          const { days, hours } = calculateDateDifferenceWithHours(
            startMerged,
            endMerged
          );
          let number = days;
          let extraCharge = 0;
          // Calculate extra charge based on hours
          if (days !== 0 && hours <= 3 && hours > 0) {
            extraCharge += 150;
          } else if (days !== 0 && hours <= 12 && hours > 0) {
            const averageDayPrice = Number(
              (data?.price?.price?.[number] / number).toFixed(2)
            );
            extraCharge += Math.round(averageDayPrice / 2);
          } else if (days !== 0 && hours > 12 && hours > 0) {
            const averageDayPrice = Number(
              (data?.price?.price?.[number] / number).toFixed(2)
            );
            extraCharge += Math.round(averageDayPrice);
          }
          setRentalDays(number);
          setExtraFee(extraCharge);
          if (number < 1) {
            setRentalDays(1);
            setExtraFee(0);
            number = 1;
          }
          if (number > 89 && data?.price?.price?.["90"]) {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["90"] / 90) * number + (extraCharge || 0)
              )
            );
          } else if (number > 59 && data?.price?.price?.["60"]) {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["60"] / 60) * number + (extraCharge || 0)
              )
            );
          } else if (number < 31) {
            setTotalPrice(data?.price?.price?.[number] + (extraCharge || 0));
          } else {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["30"] / 30) * number + (extraCharge || 0)
              )
            );
          }
        }
      } else {
        if (data?.price?.price[1]) {
          const startMerged = mergeDateAndTime(starts, startTime);
          const endMerged = mergeDateAndTime(ends, endTime);
          const { days, hours } = calculateDateDifferenceWithHours(
            startMerged,
            endMerged
          );
          let number = days;
          if (number < 1) {
            setRentalDays(1);
            if (hours == 0) {
              setTotalPrice(data?.price?.price?.[3]);
              setBoatPlan(3);
            } else if (hours <= 8) {
              setTotalPrice(data?.price?.price?.[1]);
              setBoatPlan(1);
            } else if (hours <= 12) {
              setTotalPrice(data?.price?.price?.[2]);
              setBoatPlan(2);
            } else if (hours < 24) {
              setTotalPrice(data?.price?.price?.[3]);
              setBoatPlan(3);
            } else {
              setTotalPrice(data?.price?.price?.[3] * 2);
              setBoatPlan(4);
            }
          } else {
            setRentalDays(number);
            setTotalPrice(data?.price?.price?.[3] * number);
            setBoatPlan(3);
          }
          // Calculate extra charge based on hours
          // setRentalDays(1);

          // if (pricePlan == 1) {
          //   setTotalPrice(data?.price?.price?.[1]);
          // } else if (pricePlan == 2) {
          //   setTotalPrice(data?.price?.price?.[2]);
          // } else if (pricePlan == 3) {
          //   setTotalPrice(data?.price?.price?.[3]);
          // } else if (pricePlan == 4) {
          //   setTotalPrice(data?.price?.price?.[3] * customBoatDay);
          //   setRentalDays(customBoatDay);
          // }
        }
      }
    },
    [data?.price?.price, calculateDateDifferenceWithHours, data?.isBoat]
  );

  const BookAction = (e: any) => {
    e.preventDefault();
    let startDateTime, endDateTime;

    if (start && startTime) {
      startDateTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      );
    }

    if (end && endTime) {
      endDateTime = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        endTime.getSeconds()
      );
    }
    dispatch({
      type: "ADD_PRODUCTID",
      payload: {
        startDate: startDateTime,
        endDate: endDateTime,
        pickUpLocation,
        returnLocation,
        productId: productId,
        isBoat: data?.isBoat,
      },
    });
    navigate("/checkout", {
      state: { startTime: startTime, endTime: endTime, boatPlan: boatPlan },
    });
  };

  const isDateBetween = (date: Date, start: Date, end: Date) => {
    return date > start && date < end;
  };

  useEffect(() => {
    // If unavailableDates array exists and is not empty
    if (unavailableDates && unavailableDates.length > 0) {
      // Iterate over each unavailable date range
      for (const range of unavailableDates) {
        // If startDate or endDate falls within any of the unavailable ranges
        if (
          (startDate &&
            isDateBetween(
              startDate,
              new Date(range.pickUpDate),
              new Date(range.returnDate)
            )) ||
          (endDate &&
            isDateBetween(
              endDate,
              new Date(range.pickUpDate),
              new Date(range.returnDate)
            ))
        ) {
          // Clear startDate and endDate
          setStart(null);
          setEnd(null);
          setStartTime(null);
          setEndTime(null);
          break;
        }
      }
    }
  }, [startDate, endDate, unavailableDates]);

  useEffect(() => {
    if (startDate) {
      setStart(startDate);
      setStartTime(startDate);
    }
    if (endDate) {
      setEnd(endDate);
      setEndTime(endDate);
    }
  }, [startDate, endDate]);

  const isTimeUnavailable = (time: Date, type: string) => {
    // Format both dates to yyyy-mm-dd format to compare only dates, not times
    if (start && end) {
      const selectedStartDate = format(start, "yyyy-MM-dd");
      const selectedEndDate = format(end, "yyyy-MM-dd");
      for (let i = 0; i < unavailableDates.length; i++) {
        let unavailableStartDate = format(
          new Date(unavailableDates[i].pickUpDate),
          "yyyy-MM-dd"
        );
        let unavailableEndDate = format(
          new Date(unavailableDates[i].returnDate),
          "yyyy-MM-dd"
        );

        // Check if the selected range overlaps with the unavailable range
        if (type === "pickup" && selectedStartDate == unavailableEndDate) {
          const hour = getHours(time);
          const minutes = getMinutes(time);
          // Adjust the unavailable hour by considering timezone offset (8 hours for Asia/Hong_Kong)
          const unavailableHour = getHours(
            new Date(unavailableDates[i].returnDate)
          );
          const unavailableMinutes = getMinutes(
            new Date(unavailableDates[i].returnDate)
          );
          // If the selected time is the same or after the unavailable time, then this time slot should be unavailable
          if (
            hour < unavailableHour ||
            (hour === unavailableHour && minutes < unavailableMinutes)
          ) {
            return true;
          }
        } else if (
          type === "return" &&
          selectedEndDate == unavailableStartDate
        ) {
          const hour = getHours(time);
          const minutes = getMinutes(time);
          // Adjust the unavailable hour by considering timezone offset (8 hours for Asia/Hong_Kong)
          const unavailableHour = getHours(
            new Date(unavailableDates[i].pickUpDate)
          );
          const unavailableMinutes = getMinutes(
            new Date(unavailableDates[i].pickUpDate)
          );
          // If the selected time is the same or after the unavailable time, then this time slot should be unavailable
          if (
            hour > unavailableHour ||
            (hour === unavailableHour && minutes > unavailableMinutes)
          ) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (unavailableDates && unavailableDates.length > 0 && start) {
      let i = 0;
      for (const date of unavailableDates) {
        if (isAfter(new Date(date.pickUpDate), start)) {
          setMaxDateDatepicker(new Date(date.pickUpDate));
          if (i > 0)
            setMinDateDatepicker(new Date(unavailableDates[i - 1].returnDate));
          break;
        }
        i++;
      }
    }
    // if (start && data?.isBoat) {
    //   setEnd(start);
    // }
  }, [unavailableDates, start]);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {data?.brand && (
          <div className="flex justify-between items-center z-index-10">
            <Badge color="pink" name={data?.brand} />
            <BtnLikeIcon
              carId={data?._id}
              detailPage={true}
              wishList={
                wishlists && wishlists?.includes(data?._id || "") ? true : false
              }
            />
          </div>
        )}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {data?.brand} {data?.model}
        </h2>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        {!data?.isBoat && (
          <div className="flex items-center  xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
            {data?.seats && (
              <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                <i className="las la-user-friends text-2xl"></i>
                <span className="">
                  {data?.seats} {t("SEATS", { defaultValue: "座位" })}
                </span>
              </div>
            )}
            {data?.gearbox && (
              <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                <i className="las la-dharmachakra text-2xl"></i>
                {data?.gearbox && (
                  <span className="">
                    {data?.gearbox == "Automatic"
                      ? t("AUTOGEARBOX", { defaultValue: "自動波" })
                      : t("MANUALGEARBOX", { defaultValue: "棍波" })}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            {t("INCLUDE", { defaultValue: "包括" })}{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("INCLUDED", { defaultValue: "包括" })}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE1", { defaultValue: "水、毛巾" })
                : t("INCLUDE1", { defaultValue: "電話支架、充電線及P牌" })}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE2", { defaultValue: "唱歌" })
                : t("INCLUDE2", { defaultValue: "出租前清洗乾淨及消毒車輛" })}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE3", { defaultValue: "船長、水手" })
                : t("INCLUDE3", { defaultValue: "無需承擔保險費用" })}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE4", { defaultValue: "海上泳池" })
                : t("INCLUDE4", { defaultValue: "無需承擔牌費費用" })}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE5", { defaultValue: "彩虹浮條" })
                : t("INCLUDE5", { defaultValue: "廿四小時道路支援" })}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-check-circle text-2xl"></i>
            <span>
              {data?.isBoat
                ? t("BOATINCLUDE6", { defaultValue: "2包食用冰" })
                : t("INCLUDE6", { defaultValue: "車輛行車記錄儀" })}
            </span>
          </div>

          {/* {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))} */}
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {data?.isBoat
            ? t("BOATRENTALTERMS", { defaultValue: "租賃條款" })
            : t("RENTALTERMS", { defaultValue: "包括" })}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            {data?.isBoat
              ? t("BOATTERMST1", { defaultValue: "1. 攜帶飲品" })
              : t("RENTALTERMST1", { defaultValue: "1. 保險" })}
          </h4>

          {data?.isBoat ? (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {t("BOATTERMS1", {
                defaultValue: "不可攜帶紅酒顏料飲品",
              })}
            </span>
          ) : (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {t("RENTALTERMS1", {
                defaultValue:
                  "所有出租車輛均已購買合法第三者汽車租賃保險，對第三者身體傷亡引起的法律責任為港幣一億元。如出租車輛發生意外或 任何損壞、被盜竊，租用人須負責汽車第三者保險之墊底費及自身車輛維修、全損賠償額。",
              })}
              <br />
              {t("RENTALTERMS1B", {
                defaultValue:
                  "第三者保險墊底費基本費用為港幣$35000，租用人不超過25歲或超過65歲額外墊底費$35000，持有有效香港駕駛執照不超過三年 （不包括暫準駕駛執照）額外墊底費$35000。",
              })}
            </span>
          )}
        </div>
        <div>
          <h4 className="text-lg font-semibold">
            {data?.isBoat
              ? t("BOATTERMST3", {
                  defaultValue: "2. 加時費用",
                })
              : t("RENTALTERMST2", { defaultValue: "2. 汽油" })}
          </h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {data?.isBoat
              ? t("BOATTERMS3", {
                  defaultValue: "加時$1500/小時",
                })
              : t("RENTALTERMS2", {
                  defaultValue:
                    "價目表不包括汽油，出租車輛均已入滿汽油，承租人還車時須將油缸裝滿，如本公司代入汽油，則另加港幣二百元服務費。",
                })}
          </span>
        </div>
        {!data?.isBoat && (
          <>
            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST3", { defaultValue: "3. 續租汽車" })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS3", {
                  defaultValue:
                    "未經本公司同意不能續租，續租得到本公司同意後24小時內轉帳到本公司戶口，過期租金將以單日租金計算。",
                })}
              </span>
            </div>

            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST4", { defaultValue: "4. 訂金及按金" })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS4", {
                  defaultValue:
                    "所有訂金不獲退還；不足25歲或持有不足3年正式駕駛執照或沒有香港永久性居民身份證都需要繳付雙倍按金。",
                })}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST5", { defaultValue: "5. 聲明" })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS5", {
                  defaultValue: "在過去5年沒有危險駕駛紀錄。",
                })}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST6", { defaultValue: "6. 外籍人士" })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS6", {
                  defaultValue:
                    "必須持有有效護照/通行證及年滿25歲，護照/通行證上須清楚註明香港入境日期及由香港入境處發出【訪客標籤】 持有海外駕駛執照最少兩年（如海外駕駛執照未能用英文顯示內容，則需附帶國際駕駛執照）。",
                })}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST7", {
                  defaultValue: "7. 送車及收車費用（每程計）：",
                })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS7", {
                  defaultValue:
                    "九龍區$300港島及新界區$500離島及機場$700星期六、日、公眾假期及辦公時間以外雙計。",
                })}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                {t("RENTALTERMST8", { defaultValue: "8. 里程限制" })}
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {t("RENTALTERMS8", {
                  defaultValue:
                    "里程限制為每天200公里，額外公里本公司將每公里收取港幣五元正。",
                })}
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            {data?.price?.deposit && (
              <div>
                <h4 className="text-lg font-semibold">
                  {t("SPECIALNOTE", { defaultValue: "特別說明" })}
                </h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                  - {t("DEPOSIT", { defaultValue: "按金" })}: $
                  {data?.price?.deposit} <br />
                  {/* {t("CREDITCARDHOLD")} */}
                </span>
              </div>
            )}
            {data?.price?.carCover && (
              <span className="block !mt-1 text-neutral-500 dark:text-neutral-400">
                - {t("CARCOVER", { defaultValue: "全車保 (每日)" })}: $
                {data?.price?.carCover} <br />
                {/* {t("CREDITCARDHOLD")} */}
              </span>
            )}
            {data?.price?.totalloss && (
              <span className="block !mt-1 text-neutral-500 dark:text-neutral-400">
                - {t("TOTALLOSS", { defaultValue: "全車保 (每日)" })}: $
                {data?.price?.totalloss} <br />
                {/* {t("CREDITCARDHOLD")} */}
              </span>
            )}
          </>
        )}
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* FORM */}
        <form className="hidden lg:block border border-neutral-200 dark:border-neutral-700 rounded-2xl smallcalendar">
          {/* <RentalCarDatesRangeInput /> */}
          <RentalCarDatesRangeInput
            className="flex-1"
            updateStart={start}
            updateEnd={end}
            updateStartTime={startTime}
            updateEndTime={endTime}
            start={startDate}
            end={endDate}
            productPage={true}
            unavailableDates={unavailableDates}
            changeDate={(starts: any, ends: any) => {
              setStart(starts);
              setEnd(ends);
              // if (data?.isBoat) {
              //   setEnd(starts);
              // }
              setStartTime(null);
              setEndTime(null);
            }}
            changeStartTime={(value: any) => {
              setStartTime(value);
              if (endTime && start && end)
                calculatePrice(start, end, value, endTime);
            }}
            changeEndTime={(value: any) => {
              setEndTime(value);
              if (start && end && startTime)
                calculatePrice(start, end, startTime, value);
            }}
            availableLayout={true}
            isTimeUnavailable={isTimeUnavailable}
            // isDateDisabled={isDateDisabled}
            maxDateDatepicker={maxDateDatepicker}
            minDateDatepicker={minDateDatepicker}
            setMaxDateDatepicker={setMaxDateDatepicker}
            setMinDateDatepicker={setMinDateDatepicker}
            boat={data?.isBoat}
          />
        </form>
        {/* PRICE */}

        {data?.isBoat && (
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              {t("SELECTDATERANGE", "選擇時段")} (
              {t("BOATLOCATION", "觀塘公眾碼頭、尖沙咀、中環登船")})
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5">
              {renderRadio(
                "8hrs",
                "1",
                t("BOATFIRST", {
                  defaultValue: "8小時 觀塘公眾碼頭、尖沙咀、中環登船",
                }),
                1,
                true
              )}
              {renderRadio(
                "12hrs",
                "2",
                t("BOATSECOND", {
                  defaultValue: "12小時 觀塘公眾碼頭、尖沙咀、中環登船",
                }),
                2
              )}
              {renderRadio(
                "24hrs",
                "3",
                t("BOATTHIRD", {
                  defaultValue: "24小時 觀塘公眾碼頭、尖沙咀、中環登船",
                }),
                3
              )}
            </div>
          </div>
        )}
        <div className=" justify-between flex-col hidden lg:flex">
          {!data?.isBoat && (
            <span className="text-3xl font-semibold">
              ${data?.price?.price?.["1"] ?? 0}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /{t("DAY", { defaultValue: "天" })}
              </span>
            </span>
          )}
          <div className="mt-5 flex w-full focus:outline-none rounded-2xl flex-col border border-neutral-200 dark:border-neutral-700 ">
            <div className="flex w-full ">
              <div className="flex-1 flex flex-col">
                <div className="text-base border-r border-b border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  {data?.isBoat
                    ? t("RANGE", { defaultValue: "時段" })
                    : t("DAYS", { defaultValue: "天數" })}
                </div>
                <div className="text-base border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  {t("PRICE", { defaultValue: "價格" })}
                </div>
              </div>
              <div className="flex-1 flex flex-col">
                <div className="flex-1 flex items-center justify-center  text-sm border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  {data?.isBoat
                    ? t("BOATFIRST", {
                        defaultValue: "8小時 觀塘公眾碼頭、尖沙咀、中環登船",
                      })
                    : 1}
                </div>
                <div className="flex-1 flex items-center justify-center   text-sm border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  ${priceList["1"]}
                </div>
              </div>
              <div className="flex-1 flex flex-col">
                <div className="flex-1 flex  text-sm items-center justify-center  border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  {data?.isBoat
                    ? t("BOATSECOND", {
                        defaultValue: "12小時 觀塘公眾碼頭、尖沙咀、中環登船",
                      })
                    : priceList["90"]
                    ? 7
                    : 3}
                </div>
                <div className="flex-1 flex items-center justify-center  text-sm border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                  $
                  {data?.isBoat
                    ? priceList["2"]
                    : priceList["90"]
                    ? priceList["7"]
                    : priceList["3"]}
                </div>
              </div>
              <div className="flex-1 flex flex-col">
                <div
                  className={`flex-1 flex text-sm border-b  items-center justify-center border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ${
                    !data?.isBoat && "border-r"
                  }`}
                >
                  {data?.isBoat
                    ? t("BOATTHIRD", {
                        defaultValue: "24小時 觀塘公眾碼頭、尖沙咀、中環登船",
                      })
                    : priceList["90"]
                    ? 30
                    : 7}
                </div>
                <div
                  className={`flex-1 flex text-sm items-center justify-center border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ${
                    !data?.isBoat && "border-r"
                  }`}
                >
                  $
                  {data?.isBoat
                    ? priceList["3"]
                    : priceList["90"]
                    ? priceList["30"]
                    : priceList["7"]}
                </div>
              </div>
              {!data?.isBoat && (
                <>
                  {priceList["90"] ? (
                    <>
                      {" "}
                      <div className="flex-1 flex flex-col">
                        <div className="flex-1 flex items-center justify-center text-sm border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                          60
                        </div>
                        <div className="flex-1 flex items-center justify-center  text-sm border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                          ${priceList["60"]}
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col">
                        <div className="flex-1 flex items-center justify-center  text-sm border-b border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                          90
                        </div>
                        <div className="flex-1 flex items-center justify-center  text-sm border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                          ${priceList["90"]}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="flex-1 flex flex-col">
                        <div className="flex-1 flex items-center justify-center text-sm border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                          14
                        </div>
                        <div className="flex-1 flex items-center justify-center  text-sm border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                          ${priceList["14"]}
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col">
                        <div className="flex-1 flex items-center justify-center  text-sm border-b border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                          30
                        </div>
                        <div className="flex-1 flex items-center justify-center  text-sm border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                          ${priceList["30"]}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4 ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              $
              {totalPrice
                ? ((totalPrice - extraFee) / rentalDays).toFixed(2)
                : 0}{" "}
              x {rentalDays} {t("DAY", { defaultValue: "天" })}
            </span>
            <span>${totalPrice - extraFee || 0}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {t("ADDITIONALHOURS", { defaultValue: "額外時數費用" })}
            </span>
            <span>${extraFee} </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("DEPOSIT", { defaultValue: "按金" })}</span>
            <span>${data?.price?.deposit} </span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("TOTAL", { defaultValue: "總計" })}</span>
            <span>${totalPrice || 0}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary onClick={BookAction}>
          {t("BOOK", { defaultValue: "立即預訂" })}
        </ButtonPrimary>
      </div>
    );
  };

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const renderSidebarDetail = () => {
    return (
      <>
        {(pickUpLocation || returnLocation || start || end) && (
          <div className="listingSection__wrap bottom-28 mb-10 lg:shadow-xl">
            <span className="text-2xl font-semibold block">
              {data?.isBoat
                ? t("BOATPICKUPANDRETURN", { defaultValue: "上船及落船時間" })
                : t("PICKUPANDRETURN", { defaultValue: "取車和還車" })}
            </span>
            <div className="mt-8 flex">
              <div className="flex-shrink-0 flex flex-col items-center pb-2">
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              </div>
              <div className="ml-4 space-y-14 text-sm">
                <div className="flex flex-col space-y-2">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {start?.toLocaleDateString(
                      currentLanguage == "en" ? "en-US" : "zh-HK",
                      options
                    )}{" "}
                    {startTime?.getHours().toString().padStart(2, "0")}
                    {startTime && ":"}
                    {startTime?.getMinutes().toString().padStart(2, "0")}
                  </span>
                  {pickUpLocation && (
                    <span className=" font-semibold">{pickUpLocation}</span>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {end?.toLocaleDateString(
                      currentLanguage == "en" ? "en-US" : "zh-HK",
                      options
                    )}{" "}
                    {endTime && endTime?.getHours().toString().padStart(2, "0")}
                    {endTime && ":"}
                    {endTime &&
                      endTime?.getMinutes().toString().padStart(2, "0")}
                  </span>
                  <span className=" font-semibold">{returnLocation}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className={` nc-ListingCarDetailPage `}
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <Helmet>
        <title>
          {data?.brand + " " + data?.model} - 長勝租車 GoodwayPass Car Rental
        </title>
      </Helmet>
      {/* SINGLE HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-1 gap-1 sm:gap-2">
          {/* image gallery */}
          <div className="container mx-auto px-4 xl:w-[60%]">
            {productImages?.length > 0 && (
              <ReactImageGallery
                showBullets={false}
                showFullscreenButton={false}
                showPlayButton={false}
                items={productImages}
              />
            )}

            {/* /image gallery  */}
          </div>
          {/* <div
            className="h-[300px] col-span-1 row-span-1 relative rounded-md sm:rounded-xl overflow-hidden "
            // className="h-[300px] col-span-1 row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            // onClick={handleOpenModalImageGallery}
          >
            <img
              src={data?.featuredImage}
              alt="0"
              className="absolute inset-0 object-contain rounded-md sm:rounded-xl w-full h-full"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            
          </div> */}
        </div>
      </header>

      {/* MAIn */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          <div className="block lg:hidden">{renderSidebarDetail()}</div>
          {/* {renderSectionTienIch()} */}
          {/* {renderSection2()} */}
          {renderSection3()}
          {/* <RentalCarDatesRangeInput
            className="flex-1"
            start={startDate}
            end={endDate}
            productPage={true}
            setStart={(value: any) => setStart(value)}
            setEnd={(value: any) => setEnd(value)}
            unavailableDates={unavailableDates}
            changeDate={calculatePrice}
            availableLayout={true}
          /> */}
          <div>
            <SectionDateRange
              className="flex-1"
              start={startDate}
              end={endDate}
              updateStart={start}
              updateEnd={end}
              updateStartTime={startTime}
              updateEndTime={endTime}
              productPage={true}
              unavailableDates={unavailableDates}
              changeDate={(starts: any, ends: any) => {
                setStart(starts);
                setEnd(ends);
                // if (data?.isBoat) {
                //   setEnd(starts);
                // }
                setStartTime(null);
                setEndTime(null);
              }}
              changeStartTime={(value: any) => {
                setStartTime(value);
                if (endTime && start && end)
                  calculatePrice(start, end, value, endTime);
              }}
              changeEndTime={(value: any) => {
                setEndTime(value);
                if (start && end && startTime)
                  calculatePrice(start, end, startTime, value);
              }}
              isTimeUnavailable={isTimeUnavailable}
              // isDateDisabled={isDateDisabled}
              maxDateDatepicker={maxDateDatepicker}
              minDateDatepicker={minDateDatepicker}
              setMaxDateDatepicker={setMaxDateDatepicker}
              setMinDateDatepicker={setMinDateDatepicker}
              boat={data?.isBoat}
            />
          </div>
          {/* 
          {renderSection5()}
          {renderSection6()}
          {renderSection7()} */}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          {renderSidebarDetail()}
          <div className="block sticky top-28">{renderSidebarPrice()}</div>
        </div>
      </main>

      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          <div className="">
            <span className="block text-xl font-semibold">
              ${data?.price?.price?.["1"] ?? 0}
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                /{t("DAY", { defaultValue: "天" })}
              </span>
            </span>
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <span
                  onClick={openModal}
                  className="block text-sm underline font-medium"
                >
                  {start?.toLocaleDateString(
                    currentLanguage == "en" ? "en-US" : "zh-HK",
                    {
                      month: "short",
                      day: "2-digit",
                    }
                  ) || t("ADDDATES", { defaultValue: "輸入日期" })}{" "}
                  {startTime &&
                    startTime?.getHours().toString().padStart(2, "0")}
                  {startTime && ":"}
                  {startTime &&
                    startTime?.getMinutes().toString().padStart(2, "0")}{" "}
                  {end
                    ? " - " +
                      end?.toLocaleDateString(
                        currentLanguage == "en" ? "en-US" : "zh-HK",
                        {
                          month: "short",
                          day: "2-digit",
                        }
                      )
                    : ""}
                  {endTime && endTime?.getHours().toString().padStart(2, "0")}
                  {endTime && ":"}
                  {endTime && endTime?.getMinutes().toString().padStart(2, "0")}
                  {/* {converSelectedDateToString([startDate, endDate])} */}
                </span>
              )}
              renderDates={() => (
                <SectionDateRange
                  className="flex-1"
                  start={startDate}
                  end={endDate}
                  updateStart={start}
                  updateEnd={end}
                  updateStartTime={startTime}
                  updateEndTime={endTime}
                  productPage={true}
                  unavailableDates={unavailableDates}
                  changeDate={(starts: any, ends: any) => {
                    setStart(starts);
                    setEnd(ends);
                    // if (data?.isBoat) {
                    //   setEnd(starts);
                    // }
                    setStartTime(null);
                    setEndTime(null);
                  }}
                  changeStartTime={(value: any) => {
                    setStartTime(value);
                    if (endTime && start && end)
                      calculatePrice(start, end, value, endTime);
                  }}
                  changeEndTime={(value: any) => {
                    setEndTime(value);
                    if (start && end && startTime)
                      calculatePrice(start, end, startTime, value);
                  }}
                  mobileModal={true}
                  isTimeUnavailable={isTimeUnavailable}
                  maxDateDatepicker={maxDateDatepicker}
                  minDateDatepicker={minDateDatepicker}
                  setMaxDateDatepicker={setMaxDateDatepicker}
                  setMinDateDatepicker={setMinDateDatepicker}
                />
              )}
              renderClearButton={({ closeModal }) => (
                <>
                  <button
                    type="button"
                    className="underline font-semibold flex-shrink-0"
                    onClick={() => {
                      calculatePrice(null, null, null, null);
                      setStart(null);
                      setEnd(null);
                      setStartTime(null);
                      setEndTime(null);
                      // closeModal();
                      setRentalDays(0);
                      setTotalPrice(0);
                    }}
                  >
                    {t("CLEARDATES", { defaultValue: "清除日期 " })}
                  </button>
                  <div className="flex flex-col space-y-4 justify-center ">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>
                        $
                        {totalPrice
                          ? ((totalPrice - extraFee) / rentalDays).toFixed(2)
                          : 0}{" "}
                        x {rentalDays} {t("DAY", { defaultValue: "天" })}
                      </span>
                      <span>= ${totalPrice || 0}</span>
                    </div>
                  </div>
                </>
              )}
              renderPriceTable={() => (
                <div className="mt-5 flex w-full focus:outline-none rounded-2xl flex-col border border-neutral-200 dark:border-neutral-700 ">
                  <div className="flex w-full flex-col ">
                    <div className="flex-1 flex flex-row">
                      <div className="flex-1 text-sm border-r border-b border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        {t("DAYS", { defaultValue: "天數" })}
                      </div>
                      <div className="flex-1 text-sm border-b  border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        {t("PRICE", { defaultValue: "價格" })}
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row">
                      <div className="flex-1 text-sm border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        {data?.isBoat
                          ? t("BOATFIRSTTIME", { defaultValue: "8小時" })
                          : 1}
                      </div>
                      <div className="flex-1 text-sm border-b  border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        ${priceList["1"]}
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row">
                      <div className="flex-1 text-sm border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        {data?.isBoat
                          ? t("BOATSECONDTIME", { defaultValue: "12小時" })
                          : 3}
                      </div>
                      <div className="flex-1 text-sm border-b border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                        ${data?.isBoat ? priceList["2"] : priceList["3"]}
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row">
                      <div
                        className={`flex-1 text-sm border-b  items-center justify-center border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ${
                          !data?.isBoat && "border-r"
                        }`}
                      >
                        {data?.isBoat
                          ? t("BOATTHIRDTIME", { defaultValue: "24小時" })
                          : 7}
                      </div>
                      <div
                        className={`flex-1 text-sm border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ${
                          !data?.isBoat && "border-b"
                        }`}
                      >
                        ${data?.isBoat ? priceList["3"] : priceList["7"]}
                      </div>
                    </div>
                    {!data?.isBoat && (
                      <>
                        <div className="flex-1 flex flex-row">
                          <div className="flex-1 text-sm border-b border-b border-r border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                            14
                          </div>
                          <div className="flex-1 text-sm border-b border-neutral-200 dark:border-neutral-700  font-semibold text-center  p-3 ">
                            ${priceList["14"]}
                          </div>
                        </div>
                        <div className="flex-1 flex flex-row">
                          <div className="flex-1 text-sm border-r  border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                            30
                          </div>
                          <div className="flex-1 text-sm border-neutral-200 dark:border-neutral-700 font-semibold text-center  p-3 ">
                            ${priceList["30"]}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
          {/* <ModalReserveMobile
            renderChildren={({ openModal }) => (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                // onClick={openModal}
              >
                Book
              </ButtonPrimary>
            )}
          /> */}
          <ButtonPrimary onClick={BookAction}>
            {t("BOOK", { defaultValue: "立即預訂" })}
          </ButtonPrimary>
        </div>
      </div>
    </motion.div>
  );
};

export default function ProductPage() {
  return (
    <DetailPagetLayout>
      <ProductDetail />
    </DetailPagetLayout>
  );
}
