import { FC, useContext, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "context/authContext";
import axiosInstance from "../../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import FileDropzone from "components/FileDropzone/FileDropzone";
import useImageInput from "hooks/useImageInput";

export interface AccountPageProps {
  className?: string;
}

const AccountUpload: FC<AccountPageProps> = ({ className = "" }) => {
  const { user, loadingUserData, initialLoading, dispatch } =
    useContext(AuthContext);
  const {
    previewImage: previewIdCardImage,
    setPreviewImage: setIdCardPreviewImage,
    imageChanged: idCardImageChanged,
    removeImage: removePreviewIdCardImage,
    handleChange: handleIdCardImageChange,
    selectedFile: selectedIDCardImage,
  } = useImageInput("");

  const {
    previewImage: previewCarLicenseImage,
    setPreviewImage: setCarLicensePreviewImage,
    imageChanged: CarLicenseImageChanged,
    removeImage: removeCarLicensePreviewImage,
    handleChange: handleCarLicenseImageChange,
    selectedFile: selectedCarLicenseImage,
  } = useImageInput("");

  const {
    previewImage: previewAddressDocument,
    setPreviewImage: setAddressDocumentPreviewImage,
    imageChanged: addressDocumentChanged,
    removeImage: removeAddressDocumentPreviewImage,
    handleChange: handleAddressDocumentChange,
    fileName: addressDocumentFileName,
    fileType: addressDocumentFileType,
    selectedFile: selectedAddressDocument,
  } = useImageInput("");
  const [apiLoading, setApiLoading] = useState(false);
  const [carLicenseUploaded, setCarLicenseUploaded] = useState(false);
  const [idImageUploaded, setIdImagUploaded] = useState(false);
  const [addressDocumentUploaded, setAddressDocumentUploaded] = useState(false);
  const { t } = useTranslation("account");

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    if (user) {
      if (user?.carLicense == "true") {
        setCarLicenseUploaded(true);
      }
      if (user?.idImage == "true") {
        setIdImagUploaded(true);
      }
      if (user?.addressDocument == "true") {
        setAddressDocumentUploaded(true);
      }
      // formik.setValues({
      //   engName: user.engName,
      // });
    }
  }, [user, loadingUserData, initialLoading]);

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (data: { [key: string]: any }) => {
      setApiLoading(true);
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, (data as any)[key]);
        });

        // Append the image files to the FormData object
        if (idCardImageChanged) {
          if (previewIdCardImage) {
            // const idCardField = document.querySelector(
            //   "#idImage"
            // ) as HTMLInputElement;
            if (selectedIDCardImage) {
              formData.set("idImage", selectedIDCardImage);
            }
          } else {
            formData.delete("idImage");
          }
        }

        if (CarLicenseImageChanged) {
          if (previewCarLicenseImage) {
            // const carLicenseField = document.querySelector(
            //   "#carLicense"
            // ) as HTMLInputElement;
            if (selectedCarLicenseImage) {
              formData.set("carLicense", selectedCarLicenseImage);
            }
          } else {
            formData.delete("carLicense");
          }
        }

        if (addressDocumentChanged) {
          if (previewAddressDocument) {
            // const addressDocumentField = document.querySelector(
            //   "#addressDocument"
            // ) as HTMLInputElement;
            // console.log(addressDocumentField.files);
            if (selectedAddressDocument) {
              formData.set("addressDocument", selectedAddressDocument);
            }
          } else {
            formData.delete("addressDocument");
          }
        }

        const res = await axiosInstance.put(
          `/api/v1/users/${user._id}`,
          formData
        );
        if (res.status == 200) {
          setApiLoading(false);
          setAlert({
            show: true,
            message: `${t("DOCUMENTUPLOADED", {
              defaultValue: "上傳成功!",
            })}`,
            type: "success",
          });
          dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
          setIdCardPreviewImage(undefined);
          setAddressDocumentPreviewImage(undefined);
          setCarLicensePreviewImage(undefined);
        }
      } catch (err: any) {
        console.log(err);
        setApiLoading(false);
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0 && formik.isSubmitting) {
      formik.setSubmitting(false);
      const firstErrorKey = errorKeys[0];
      const firstErrorElement =
        document.getElementsByName(firstErrorKey)[0] ||
        document.getElementsByClassName(firstErrorKey)[0];
      if (firstErrorElement) {
        const headerHeight = 120;
        const rect = firstErrorElement.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const topPosition = rect.top + scrollTop - headerHeight;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  const firstErrorRef = useRef(null);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | 長勝租車 GoodwayPass Car Rental</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
            {t("UPLOADDOCUMENT", "上傳文件")}
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form onSubmit={formik.handleSubmit} className="">
            <div className="flex items-start justify-between w-full flex-wrap">
              <FileDropzone
                label={t("UPLOADIDCARD", "上傳身份證")}
                fieldName="idImage"
                fileUrl={previewIdCardImage}
                onChange={handleIdCardImageChange}
                removePreviewImage={removePreviewIdCardImage}
                uploaded={idImageUploaded}
              />
              <FileDropzone
                label={t("UPLOADCARLICENSE", "上傳駕駛執照")}
                fieldName="carLicense"
                fileUrl={previewCarLicenseImage}
                onChange={handleCarLicenseImageChange}
                removePreviewImage={removeCarLicensePreviewImage}
                uploaded={carLicenseUploaded}
              />

              <FileDropzone
                label={t("UPLOADADDRESSDOCUMENT", "上傳地址證明文件")}
                fieldName="addressDocument"
                fileUrl={previewAddressDocument}
                onChange={handleAddressDocumentChange}
                fileType={addressDocumentFileType}
                fileName={addressDocumentFileName}
                removePreviewImage={removeAddressDocumentPreviewImage}
                uploaded={addressDocumentUploaded}
              />
            </div>
            <div className=" mt-10 md:mt-0  max-w-3xl space-y-6 mx-auto">
              <div className="pt-2 text-center">
                <ButtonPrimary
                  type="submit"
                  disabled={apiLoading}
                  className="px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
                >
                  {t("CONFIRMUPLOAD", "確認上傳")}
                  {apiLoading ? (
                    <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                  ) : null}
                </ButtonPrimary>
              </div>
              {alert.show && (
                <Alert type={alert.type} message={alert.message} className="" />
              )}
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountUpload;
