import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "context/authContext";
import axiosInstance from "../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
export interface PageLoginProps {
  className?: string;
  checkout?: boolean;
}

const LoginPage: FC<PageLoginProps> = ({ className = "", checkout }) => {
  const [redirectAlert, setRedirectAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const navigate = useNavigate();
  const [apiLoading, setApiLoading] = useState(false);
  const location = useLocation();
  const { t } = useTranslation("login");

  useEffect(() => {
    if (location.state && location.state.alertMessage) {
      setRedirectAlert({
        show: true,
        message: location.state.alertMessage,
        type: "error",
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  const { loading, error, dispatch } = useContext(AuthContext);

  const validationSchema = Yup.object({
    usernameOrEmail: Yup.string().required(
      `${t("EMAILREQUIRED", { defaultValue: "電子郵件/用戶名為必填 " })}!`
    ),
    password: Yup.string().required(
      `${t("PASSWORDREQUIRED", { defaultValue: "密碼為必填 " })}!`
    ),
  });

  const formik = useFormik({
    initialValues: {
      usernameOrEmail: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setApiLoading(true);
      dispatch({ type: "LOGIN_START" });
      try {
        const res = await axiosInstance.post("api/v1/auth/login", values);
        if (res.status == 200) {
          setApiLoading(false);
          setAlert({
            show: true,
            message: `${t("LOGINSUCCESS", { defaultValue: "登入成功 " })}!`,
            type: "success",
          });
          setTimeout(() => {
            {
              !checkout ? navigate("/account") : window.location.reload();
              dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
            }
          }, 1500);
        }
      } catch (err: any) {
        setApiLoading(false);
        dispatch({ type: "LOGIN_FAILURE", payload: err?.response?.data });
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
  });

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <motion.div
        className={`nc-PageLogin ${className}`}
        data-nc-id="PageLogin"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <Helmet>
          <title>
            {t("LOGIN", { defaultValue: "登入" })} - 長勝租車 GoodwayPass Car
            Rental
          </title>
        </Helmet>

        {redirectAlert?.show && (
          <Alert
            type={redirectAlert.type}
            message={redirectAlert.message}
            className="w-full"
          />
        )}

        <div
          className={
            !checkout ? "container mb-24 lg:mb-32" : "container mb-2 lg:mb-2"
          }
        >
          {!checkout ? (
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              {t("LOGIN", { defaultValue: "登入" })}
            </h2>
          ) : (
            <br />
          )}

          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <form
              onSubmit={formik.handleSubmit}
              className="grid grid-cols-1 gap-6"
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  {/* Email / Username */}
                  {t("EMAIL", { defaultValue: "電郵地址" })}
                </span>
                <Input
                  id="usernameOrEmail"
                  type="text"
                  placeholder="example@example.com"
                  className="mt-1"
                  onChange={formik.handleChange}
                  value={formik.values.usernameOrEmail}
                />
                {formik.touched.usernameOrEmail &&
                formik.errors.usernameOrEmail ? (
                  <p className="text-red-600">
                    {formik.errors.usernameOrEmail.toString()}
                  </p>
                ) : null}
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  {t("PASSWORD", { defaultValue: "密碼" })}
                  <Link to="/forgot-password" className="text-sm">
                    {t("FORGOTPASSWORD", { defaultValue: "忘記密碼" })}?
                  </Link>
                </span>
                <Input
                  id="password"
                  type="password"
                  className="mt-1"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-600">
                    {formik.errors.password.toString()}
                  </p>
                ) : null}
              </label>
              <ButtonPrimary type="submit" disabled={apiLoading}>
                {t("CONTINUE", { defaultValue: "繼續" })}
                {apiLoading ? (
                  <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                ) : null}
              </ButtonPrimary>
            </form>

            {/* ==== */}
            {!checkout && (
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                {t("NEWUSER", { defaultValue: "新用戶" })}? {` `}
                <Link to="/register">
                  {t("CREATEACCOUNT", { defaultValue: "創建帳戶" })}
                </Link>
              </span>
            )}
            {alert?.show && (
              <Alert
                type={alert.type}
                message={alert.message}
                className="w-full"
              />
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default LoginPage;
