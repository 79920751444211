import React from "react";

const LoadingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-16 h-16 border-4 border-l-transparent border-neutral-900 dark:border-neutral-50 rounded-full animate-spin"></div>
      {/* <span className="block mt-4 text-neutral-700 dark:text-neutral-300">Loading...</span> */}
    </div>
  );
};

export default LoadingPage; 