import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: ReactNode;
  btnText: string;
  repair?: boolean;
  btnLink?: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  repair,
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
  btnLink="/contact",
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div
          className={`w-screen max-w-full xl:max-w-lg ${
            repair && "xl:!max-w-[60%]"
          } space-y-5 lg:space-y-7`}
        >
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <div className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </div>
          {!!btnText && (
            <ButtonPrimary href={btnLink}>{btnText}</ButtonPrimary>
          )}
        </div>
        <div className="flex-grow">
          <img
            className={`${!repair ? "w-full" : "w-[500px]"}`}
            src={rightImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
